import React, {useEffect} from 'react';
import LeafGrid from '../LeafGrid';
import HeroContent from './HeroContent';

const HeroHome= ({title, headline, subheadline, idx, button}) => {

  // useEffect(() => {
  //   window.dataLayer.push({
  //     event: 'pageview',
  //     pageTitle: title ? title : 'Aspire Education',
  //     pagePath: window.location.href,
  //   });
  // }, []);

  return (
    <div id={idx} className="heroclass home-hero container-fluid mb-3 d-flex flex-column position-relative pt-5 py-4 py-lg-5">
      <div className="container d-flex flex-column flex-lg-row align-items-start flex-grow-1 position-relative z-1">
        <div className="col-12 col-lg-9">
          <HeroContent
          headline = {headline}
          subheadline = {subheadline}
          subhmaxwidth = {'mw-xs'}
          button = {button}
          ></HeroContent>
        </div>
        <div className="d-none d-lg-block col-12 col-lg-3">
          <LeafGrid></LeafGrid>
        </div>
      </div>
    </div>
  );
}

export default HeroHome;