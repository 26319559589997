import Nav from 'react-bootstrap/Nav';

const NavLinks= () => {
  return (
    <>
    <Nav.Link href="ueber-die-gruppe">über uns</Nav.Link>
    <Nav.Link href="aspire-brands">aspire brands</Nav.Link>
    <Nav.Link href="join-us">join us</Nav.Link>
    </>
  );
}

export default NavLinks;