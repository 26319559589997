const Person= ({image, name, position, company, contact}) => {

  return (
    <>
      <div className="col-auto">
        <img src={image ? image?.fields?.file?.url + '?w=150' : ''} 
              className="bp-image rounded-100"
              width={150}
              height={150}
              alt={name}>
        </img>
      </div>
      <div className="col-9 col-md-12 col-lg-6">
      {name ? 
        <h3 className="fs-5 fs-md-2 mt-3 mt-md-0 mb-1 text-primary block-type-heading lh-1">{name}</h3>
      : null }
      {position ? 
        <div className="fs-6 fs-md-5 mb-1 block-type-heading lh-1">{position}</div>
      : null }
      {company ? 
        <div className="fs-6 fs-md-5 mb-1 block-type-heading lh-1 fw-bold">{company}</div>
      : null }
      {contact ? 
      <div className="col-12 mt-4 d-flex justify-content-end justify-content-sm-start">
      { contact[0] ? 
        <a href={contact[1] ? "mailto:" + contact[1] : '#kontakt'} className="asp-btn btn btn-primary bg-hover-secondary text-white text-hover-black rounded-20 rounded-bottom-0 border-0 p-2 p-md-3 min-h-45px w-100 d-flex align-items-center justify-content-center" role="button">
          {contact[0] ? <span>{contact[0]}</span> : null}
        </a>
      : null }
      </div>
      : null }
      </div>
    </>
  );
}

export default Person;