import React from 'react';
import Logo from './../../../../assets/img/logo.svg';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavLinks from './NavLinks';
import LanguageSwitcher from './LanguageSwitcher';
import Offcanvas from 'react-bootstrap/Offcanvas';

const Header= ({transUrl}) => {
  return (
    <div className="headerclass container-fluid">
      {['lg'].map((expand) => (
        <Navbar key={expand} expand={expand} className="py-0">
          <Container className="container-fluid">
            <Navbar.Brand className="navbar-brand logo d-flex align-items-center ps-2 px-sm-0" href='/en/'>
              <img src={Logo} alt="Aspire Education Logo" width="213" height="50" ></img>
              {/* <span className="site-title text-dark fw-bold fs-5 d-inline-flex ms-1">Aspire Education</span> */}
            </Navbar.Brand>
            <Navbar.Toggle className='shadow-none border-0' aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Nav className="justify-content-between flex-grow-0 pe-3 d-none d-lg-flex col-6 fs-7 fw-bolder">
              <NavLinks></NavLinks>
              <LanguageSwitcher className="lang-switcher" transUrl={transUrl}></LanguageSwitcher>
            </Nav>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              className="bg-light text-dark fs-4 fw-bold"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <Navbar.Brand className="navbar-brand logo d-flex align-items-center" href='/en/'>
                  <img src={Logo} alt="Aspire Education Logo" width="146" height="35" ></img>
                </Navbar.Brand>
                </Offcanvas.Title>
                <LanguageSwitcher className="mobile-lang-switcher" transUrl={transUrl}></LanguageSwitcher>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3 mob-nav">
                  <NavLinks></NavLinks>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </div>
  );
}

export default Header;