import React, {useEffect} from 'react';
import HeroContent from './HeroContent';

const Hero= ({headline, subheadline, idx, button}) => {

  // useEffect(() => {
  //   window.dataLayer.push({
  //     event: 'pageview',
  //     pageTitle: title ? title : 'Aspire Education',
  //     pagePath: window.location.href,
  //   });
  // }, []);

  return (
    <div id={idx} className="heroclass hero container-fluid mb-3 d-flex flex-column position-relative pb-4 pt-5">
      <div className="container d-flex flex-column flex-lg-row align-items-start flex-grow-1 position-relative z-1">
        <div className="col-12">
          <HeroContent
          h1maxwidth = {'mw-xs'}
          headline = {headline}
          subheadline = {subheadline}
          button = {button}
          ></HeroContent>
        </div>
      </div>
    </div>
  );
}

export default Hero;