import Nav from 'react-bootstrap/Nav';
// import { useTranslation } from 'react-i18next';

const NavLinks= () => {
  // const { t, i18n } = useTranslation();
  return (
    <>
    <Nav.Link href="about-group">about the group</Nav.Link>
    <Nav.Link href="aspire-brands">aspire brands</Nav.Link>
    <Nav.Link href="join-us">join us</Nav.Link>
    </>
  );
}

export default NavLinks;