import React, {useEffect} from 'react';
import ReactMarkdown from "react-markdown";

const HeroContent= ({headline, h1maxwidth, subheadline, subhmaxwidth, button}) => {

  // useEffect(() => {
  //   window.dataLayer.push({
  //     event: 'pageview',
  //     pageTitle: title ? title : 'Aspire Education',
  //     pagePath: window.location.href,
  //   });
  // }, []);

  return (
    <>
    {headline ? 
    <h1 className={h1maxwidth ? h1maxwidth + ' fw-bold mt-lg-4 lh-1 mb-3' : 'fw-bold mt-lg-4 lh-1 mb-3'} fetchpriority="high">{headline}</h1>
    : null }
    {subheadline ?
      <div className={subhmaxwidth ? subhmaxwidth + ' fs-8 fs-md-5 pill-list' : 'fs-8 fs-md-5 pill-list'}>
      <ReactMarkdown>{subheadline}</ReactMarkdown>
      </div>
    : null }
      { button ? 
      <div className="col-12 col-sm-8 col-md-6 col-lg-3 mt-4 d-flex justify-content-end justify-content-sm-start">
        { button[0] ? 
          <a href={button[1] ? button[1] : '#kontakt'} className="asp-btn btn btn-primary bg-hover-secondary text-white text-hover-black rounded-20 rounded-bottom-0 border-0 py-2 py-md-3 px-4 px-sm-5 min-h-45px w-auto d-flex align-items-center justify-content-center" role="button">
            {button[0] ? <span>{button[0]}</span> : null}
          </a>
        : null }
      </div>
      : null }
    </>
  );
}

export default HeroContent;