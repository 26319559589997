import React, {useEffect, useState} from 'react';
import SEO from "../Components/SEO";
// import { Helmet } from 'react-helmet-async';
import Header from '../Components/Header/Header';
import { createClient } from 'contentful';
import HeroHome from '../Components/HeroSection/HeroHome';
import Content from '../Components/Content';
import Footer from '../Components/Footer';


const Page = () => {

    const [page, setPage] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    // const load = 5;
  
    const client = createClient({
      space: 'yeka5wqskhix',
      environment: 'master',
      accessToken: 'I48J-PULdPKW0L6xgXE91RDXkZlGjqmQsAand6Ln00k'
    })
  
    useEffect (() => {
      const getPage = async () => {
        try {
          await client.getEntry('2s2pOWmH29fAhqlXPt4eKD').then((entry) => {
            setPage(entry);
            console.log(entry);
            setIsDataLoaded(true);
          })
        } catch(error) {
          console.log(error);
        }
  
      }
      getPage();
  
    }, [])
  
    if (!page) {
      return "Loading...";
    }

  return (
    
    <div className="home startseite">
      <Header transUrl = {page?.fields?.transUrl ? page?.fields?.transUrl : ''}></Header>

      <SEO
      title={page.fields?.seoFields?.fields?.metaTitle ? page.fields?.seoFields?.fields?.metaTitle : page.fields?.title}
      metaTitle={page.fields?.seoFields?.fields?.metaTitle ?
      page.fields?.seoFields?.fields?.metaTitle + ' | Aspire Education'
      : page.fields?.title + ' | Aspire Education'}
      description={page.fields?.seoFields?.fields?.metaDescription}
      />

      <HeroHome 
      headline={page.fields?.headline}
      subheadline={page.fields?.subHeadline}
      idx={page.fields?.slug}
      title={page.fields?.seoFields?.fields?.metaTitle ? page.fields?.seoFields?.fields?.metaTitle : page.fields?.title}
      button={page?.fields?.heroButton ? page?.fields?.heroButton : ''}
      ></HeroHome>

      <main className="home-main">

      <div className="container-fluid">
        <div className="container position-relative z-1 home-main-inner">
          { isDataLoaded ? <Content id={page?.sys?.id}></Content> : null }
        </div>
      </div>

      </main>

      <Footer></Footer>
      
    </div>
  );
}

export default Page;