const LeafGrid= () => {

  return (
    <div className="leaf-grid">
      <div className="div1"></div>
      <div className="div2"></div>
      <div className="div3"></div>
      <div className="div4"></div>
      <div className="div5"></div>
    </div>
  );
}

export default LeafGrid;