import React, {useEffect, useState} from 'react';
import SEO from '../Components/SEO';
// import GTM from '../Components/Gtm';
import Header from '../Components/Header/Header';
import { createClient } from 'contentful';
import { useParams } from 'react-router-dom';
import Hero from '../Components/HeroSection/Hero';
import Content from '../Components/Content';
import Footer from '../Components/Footer';

const Default = () => {

  const [singleEntry, setSingleEntry] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const client = createClient({
    space: 'yeka5wqskhix',
    environment: 'master',
    accessToken: 'I48J-PULdPKW0L6xgXE91RDXkZlGjqmQsAand6Ln00k'
  })

  let { slug } = useParams();

  useEffect (() => {
    const getEntries = async () => {
      try {
        await client.getEntries({
          content_type: "page",
          limit: 1,
          "fields.slug": slug,
        }).then((entries) => {
          setSingleEntry(entries)
          setIsDataLoaded(true);
          //console.log(entries);
        })
      } catch(error) {
        console.log(error);
      }

    }
    getEntries()

  }, [])

  if (!singleEntry) {
    return "Loading...";
  }

  return (
    <div className="container-fluid">
      {singleEntry?.items?.map((entry) =>
      <div className="single-entry single-page" key={entry?.sys?.id}>
        <Header transUrl = {entry?.fields?.transUrl ? entry?.fields?.transUrl : ''}></Header>
        <SEO
        title={entry.fields?.seoFields?.fields?.metaTitle ? entry.fields?.seoFields?.fields?.metaTitle : entry.fields?.title}
        metaTitle={entry.fields?.seoFields?.fields?.metaTitle ?
          entry.fields?.seoFields?.fields?.metaTitle + ' | Aspire Education'
          : entry.fields?.title + ' | Aspire Education'}
        description={entry.fields?.seoFields?.fields?.metaDescription}
        />

        {/* <GTM title={entry.fields?.seoFields?.fields?.metaTitle ? entry.fields?.seoFields?.fields?.metaTitle : ''} /> */}

        <Hero
        headline={entry.fields?.headline}
        subheadline={entry.fields?.subHeadline}
        idx={entry.fields?.slug}
        button={entry.fields?.heroButton ? entry.fields?.heroButton : ''}
        ></Hero>

        {/*Main Content*/}
        <main className="default-main">

        <div className="container-fluid">
          <div id={entry.fields?.slug + '-wrapper'} className="container position-relative z-1 default-main-inner">
            { isDataLoaded ? <Content id={entry?.sys?.id}></Content> : null }
          </div>
        </div>

        </main>
        {/*end::Main Content*/}

        <Footer></Footer>

      </div>
)}
    </div>
  )
}

export default Default