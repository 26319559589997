import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({title, metaTitle, description, author, article, datePublished, dateModified, authorUrl, author_url, fc_image, type, image, canonical, fc_name, fc_summary, fc_author, fc_claimReviewed, fc_claimUrl, fc_claimHeadline, fc_claimPublished, fc_claimAuthor, fc_claimAuthorType, fc_claimAuthorUrl, fc_reviewRating}) {
  const siteUrl = window.location.href.split(/[?#]/)[0];
  return (
    <Helmet>
      { /* Standard metadata tags */ }
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonical ? canonical : siteUrl}/>
      { /* End standard metadata tags */ }
      { /* Facebook tags */ }
      <meta property="og:type" content={type ? type : 'website'} />
      <meta property="og:site_name" content="aspire-education.com" />
      <meta property="og:url" content={siteUrl}></meta>
      <meta property="og:title" content={metaTitle ? metaTitle : title ? title : ''} />
      <meta property="og:description" content={description ? description : ''} />
      <meta property="og:locale" content="de_DE" />
      <meta property="og:image" content={image ? image : ''} />
      { /* End Facebook tags */ }
      { /* Twitter tags */ }
      <meta name="twitter:creator" content={author} />
      <meta name="twitter:site" content="@" />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={metaTitle ? metaTitle : title ? title : ''} />
      <meta name="twitter:description" content={description ? description : ""} />
      { /* End Twitter tags */ }
      <script type="application/ld+json">
      {JSON.stringify({
        "@context": "http://www.schema.org",
        "@id": "Organization",
        "@type": "Organization",
        "name": "Aspire Education GmbH",
        "url": "https://www.aspire-education.com/",
        "logo": {
        "@type": "ImageObject",
        "url": "https://www.aspire-education.com/logo.png"
        },
        "address": {
        "@type": "PostalAddress",
        "streetAddress": "",
        "addressLocality": "",
        "postalCode": "",
        "addressCountry": ""
        },
        "contactPoint" : [{ "@id": "CustomerServicePhoneNumber" }]
      })}
      </script>
      
      { fc_name ? 
      <script type="application/ld+json">
      {JSON.stringify({
        "@context": "http://www.schema.org",
        "@type": "ClaimReview",
        "url": siteUrl,
        "claimReviewed": fc_claimReviewed,
        "itemReviewed": {
          "@type": "Claim",
          "author": {
            "@type": fc_claimAuthorType,
            "name": fc_claimAuthor ? fc_claimAuthor : author,
            "sameAs": fc_claimAuthorUrl
          },
          "datePublished": fc_claimPublished,
          "appearance": {
            "@type": "OpinionNewsArticle",
            "image": fc_image,
            "url": fc_claimUrl,
            "headline": fc_claimHeadline,
            "datePublished": fc_claimPublished,
            "author": {
              "@type": "Person",
              "name": author,
              "url": fc_claimAuthorUrl
            },
          }
        },
        "author": {
          "@type": "Organization",
          "name": "Aspire Education GmbH"
        },
        "reviewRating": {
          "@type": "Rating",
          "alternateName": fc_reviewRating
        }
        })}
      </script>
      : null }
    </Helmet>
  )
}
