import Nav from 'react-bootstrap/Nav';

const LanguageSwitcher= ({className, transUrl}) => {  
 const baseUrl = window.location.origin.toString();
 const langUrl = (baseUrl + '/' + transUrl);
  return (
    <div className={className ? className + ' language-switcher d-flex align-items-center' : 'language-switcher d-flex align-items-center'}>
        <Nav.Link 
        href={langUrl ? langUrl : ''}
        className={'idle'}
        style={{ fontWeight: 'normal' }}>DE</Nav.Link>
        <Nav.Link 
        className={'active'}
        style={{ fontWeight: 'bold' }}>EN</Nav.Link>
    </div>
  );
}

export default LanguageSwitcher;