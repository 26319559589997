import React, { useState, useEffect } from "react";
//import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ReactMarkdown from "react-markdown";
const contentful = require('contentful-management');

const ContactForm = ({spbf, sbt, fsn}) => {

  const [msg, setMsg] = useState('');
  const [show, setShow] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    console.log(data.favorite_color);
    if (data.favorite_color !== "") {
      console.log('Form submission is spam')
      return;
    } else {

    const client = contentful.createClient({
      accessToken: 'CFPAT-aQvvGQ32keBQ-kBYUg7VM19pQbWRS9kBzejp_SRmQio',
    })
  
    function guidGenerator() {
      var S4 = function() {
         return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
      };
      return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
    }
  
    const idm = guidGenerator();

  client.getSpace('yeka5wqskhix')
  .then((space) => space.getEnvironment('master'))
  .then((environment) => environment.createEntryWithId('contactEntries', idm, {
    fields: {
      firstName: {
        'de': data.firstName
      },
      companyName: {
        'de': data.companyName
      },
      message: {
        'de': data.message
      },
      email: {
        'de': data.email
      }
    }
  }))
  .then((entry) => console.log(entry))
  .catch(console.error)
  setShow(true);
  reset();
  }
  };

  return (
    <div id="kontakt" className="container p-0">
      <Alert show={show} variant="success">
        <ReactMarkdown>{fsn}</ReactMarkdown>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={() => setShow(false)} variant="outline-success">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
          </svg>
          </Button>
        </div>
      </Alert>

      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label htmlFor="firstName" className="text-grey">Nachname</label>
          <input 
          type="text" 
          id="firstName" 
          className="form-control rounded-6"
          aria-label="Nachname" 
          name="firstName" {...register("firstName", {
            required: "First Name is Required.",
            pattern: {
              value: /^[A-Z][a-zA-Z '.-]*[A-Za-z][^-]$/,
              message: "Name is not valid."
            }
          })} />
          {errors.firstName && <p className="errorMsg">{errors.firstName.message}</p>}
        </div>
        
        <div className="mb-3">
          <label htmlFor="email" className="text-grey">E-mail*</label>
          <input 
          type="text" 
          id="email" 
          className="form-control rounded-6"
          aria-label="email" 
          name="email" {...register("email", {
            required: "Email is required.",
            pattern: {
              value: /^\w[\w.-]*@([\w-]+\.)+[\w-]+$/,
              message: "Email is not valid."
            }
          })} />
          {errors.email && <p className="errorMsg">{errors.email.message}</p>}
        </div>

        <div className="mb-3">
          <label htmlFor="companyName" className="text-grey">Unternehmensname</label>
          <input 
          type="text" 
          id="companyName" 
          className="form-control rounded-6"
          aria-label="Unternehmensname" 
          name="companyName" {...register("companyName", {
            pattern: {
              value: /^[A-Z][a-zA-Z '.-]*[A-Za-z][^-]$/,
              message: "Name is not valid."
            }
          })} />
          {errors.companyName && <p className="errorMsg">{errors.companyName.message}</p>}
        </div>

        <div className="mb-0">
          <label htmlFor="message" className="text-grey">Ihre Nachricht an uns*</label>
          <textarea 
          rows="4"
          id="message" 
          className="form-control rounded-6"
          aria-label="Ihre Nachricht an uns" 
          name="message" {...register("message", {
            required: "Message is required."
          })} />
          {errors.message && (
            <p className="errorMsg">{errors.message.message}</p>
          )}
        </div>
        <label 
        htmlFor="favorite_color" 
        aria-label="Wenn Sie dies lesen können, lassen Sie dieses Feld leer" 
        style={{color: '#C6D2DC', fontSize: '1px', lineHeight: '1px', height: '1px'}}>Wenn Sie dies lesen können, lassen Sie dieses Feld leer</label>
        <input 
        type="text"
        name="favorite_color" {...register("favorite_color" )}
        id="favorite_color"
        style={{color: '#C6D2DC', backgroundColor: '#C6D2DC', fontSize: '1px', lineHeight: '1px', border: 'none', height: '1px'}}
        />

        <div className="mb-4 fs-11 lh-sm text-grey">
        <ReactMarkdown>{spbf}</ReactMarkdown>
        </div>
        <div className="">
          <button
          type="submit" 
          className="g-recaptcha asp-btn btn btn-primary bg-hover-secondary text-white text-hover-black rounded-20 rounded-bottom-0 border-0 py-2 py-md-3 px-5 min-h-45px w-auto d-flex align-items-center justify-content-center">{sbt}</button>
        </div>
      </form>
    </div>
  )
}

export default ContactForm