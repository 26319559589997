import './assets/styles/style.css';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import { Suspense } from 'react';
import Home from './content/Pages/Home';
import HomeEn from './content/TEN/Pages/Home';
import Default from './content/Pages/Default';
import DefaultEn from './content/TEN/Pages/Default';
// import TagManager from 'react-gtm-module';

// const tagManagerArgs = {
//   gtmId: 'GTM-NZC6R6C'
// }

// TagManager.initialize(tagManagerArgs);

const helmetContext = {};
// const Default = lazy(() => import('./content/Pages/Default' ));
// const Home = lazy(() => import('./content/Pages/Home' ));

function App() {
  return (
    <HelmetProvider context={helmetContext}>
    <Router>
      <Routes>
        <Route exact caseSensitive path="/" element={<Home />} />
        <Route exact path="/home"
          element={<Navigate to="/" replace />}
        />
        <Route exact path="/en/home"
          element={<Navigate to="/en/" replace />}
        />
        <Route exact caseSensitive path="/en/" element={<HomeEn />} />
        <Route exact caseSensitive path="/en/:slug" element={<Suspense fallback={<></>}> <DefaultEn /> </Suspense>}/>
        <Route exact caseSensitive path="/:slug" element={<Suspense fallback={<></>}> <Default /> </Suspense>}/>
      </Routes>
      <ScrollToTop smooth color="#36B37E" />
    </Router>
    </HelmetProvider>
  );
}

export default App;
